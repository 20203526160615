.App {
  font-family: 'Times New Roman', Times, serif;
  color: darkslategray;
  background-color: azure;
}

h1 {
  margin: 15px 0 0 0;
}

/* This was the default react-app header style.
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
*/

.App-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background-color: azure;
}

.Penrose-background {
  width: auto;
  height: 100%;
}

.Button-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
}

.Button-box {
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.SortButton {
  font-size: 15px;
  text-align: center;
  font-weight: normal;
  min-width: 150px;
  padding: 10px;
  margin: 20px;
  background-color: lightcyan;
  color: darkslategray;
}

.About-container {
  padding: 0 15px;
}

.Computer-image {
  max-width: 100%;
  height: auto;
}

@media only screen and (min-width: 910px) {

  /* Buttons to the side */
  .App-container {
    flex-direction: row;
  }
}