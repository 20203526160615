body {
  margin: 0;
  font-family: "Times New Roman", Times, serif;
  color: darkslategray;
  background-color: azure;
}

p {
  font-size: 18px;
}

a {
  text-decoration: none;
}
